import React from "react";
import AppAuthed from "./AppAuthed";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AppAuthed user={undefined} signOut={undefined} />
      </header>
    </div>
  );
}

export default App;
