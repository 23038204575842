const envs = {
  dev: {
    // region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_xERj2ytvu",
    userPoolClientId: "2hkooja1gdq94enh9nqb9muho2",
  },
  qa: {
    userPoolId: "ap-southeast-2_fe6Y8lmnQ",
    userPoolClientId: "49gc46rj1doavdidtno5m76tad",
  },
  prod: {
    userPoolId: "ap-southeast-2_j1Ogdnl9G",
    userPoolClientId: "4r43b7f72tkomre9i2c62ce8ir",
  },
};

export default envs;
