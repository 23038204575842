import { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { AuthUser } from "aws-amplify/auth";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import environments from "./envs";
import { ReactComponent as Loader } from "./loader.svg";

Amplify.configure({
  Auth: { Cognito: environments.prod },
});

export const fetchIdTokenString = async () => {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken?.toString() ?? "";
  } catch (error) {
    console.error("Error fetching idToken:", error);
    return "";
  }
};

function AppAuthed({
  user = undefined,
  signOut = undefined,
}: {
  user: AuthUser | undefined;
  signOut: any | undefined;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    async function fetchData() {
      const awsIdToken = await fetchIdTokenString();
      setIdToken(awsIdToken);
    }
    fetchData();
  }, []);

  const handleDelRequest = async () => {
    if (!idToken) {
      console.warn("idToken is null");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://api.moregooddays.com/user-data-removal",
        {
          method: "POST",
          headers: {
            Authorization: idToken,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("removal res:", response);
    } catch (err) {
      console.error("request error:", err);
    }

    setIsLoading(false);
  };

  console.log("user here:", user);
  return (
    <>
      <h1>MoreGoodDays Data Deletion Portal</h1>
      <br />
      <br />
      <button className="delete-btn mgd-btn " onClick={handleDelRequest}>
        {!isLoading ? "Delete" : <Loader className="spinner" />}
      </button>
      <br />
      <p>
        By clicking the button above, you will request all data attached to{" "}
        <span style={{ textDecoration: "underline" }}>
          {user?.signInDetails?.loginId}
        </span>{" "}
        to be removed and deleted from our app.
      </p>

      <button className="sign-out-btn mgd-btn " onClick={signOut}>
        Sign out
      </button>
    </>
  );
}

export default withAuthenticator(AppAuthed, { hideSignUp: true });
